import { graphql } from 'gatsby';
import React from 'react';
import IneligibleQuotePageTemplate, {
  IneligibleQuotePageProps,
} from 'templates/IneligibleQuotePage';
import UserLoggedInWrapper from 'components/UserLoggedInWrapper';

export const query = graphql`
  query {
    csIneligibleQuotePage(page_id: { eq: "motor-ineligible-mta-quote" }) {
      page_id
      ...IneligibleQuotePage
    }
  }
`;

const IneligibleMtaQuotePage: React.FC<IneligibleQuotePageProps> = ({ data }) => {
  if (!data.csIneligibleQuotePage) {
    throw new Error(
      'Ineligible quote page with page_id `motor-ineligible-mta-quote` not found'
    );
  }

  return (
    <UserLoggedInWrapper>
      <IneligibleQuotePageTemplate data={data} pageType="accountArea" />
    </UserLoggedInWrapper>
  );
};

export default IneligibleMtaQuotePage;
